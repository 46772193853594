<template>
    <div class="OffsetTop JournalNavigation">
        <router-link to="/journal">2023</router-link> |
        <router-link to="/journal/2022">2022</router-link>
        <!--router-link to="/journal/2021">2021</router-link> |
        <router-link to="/journal/2020">2020</router-link-->
  </div>
</template> 



<style lang="scss">
.JournalNavigation {
	color: #cf4e8f;
	text-align: center;
}

.JournalNavigation a {
	font-weight: bold;
}
</style>