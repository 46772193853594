<template>
  <div class="home">
    <componentEqipmentAndInteriors msg="Оборудование и интерьеры фото-фитнес-студии ArtPro"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import componentEqipmentAndInteriors from '@/components/EqipmentAndInteriors.vue' // @ is an alias to /src

@Options({
  components: {
    componentEqipmentAndInteriors
  }
})
export default class EqipmentAndInteriors extends Vue {}
</script>
