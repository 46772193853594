
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    msg: String,
    year : Number
  },
  // watch: {
  //   title: {
  //     immediate: true,
  //     handler() {
  //       let year = this.$route.params.year ? this.$route.params.year : 2023;
  //       document.title = `Журнал ${year} - Фото-Фитнес-Студия ArtPro`;
  //     }
  //   }
  // }
  created () {
    // let year = this.$route.params.year ? this.$route.params.year : 2023;
    // document.title = `Журнал ${year} - Фото-Фитнес-Студия ArtPro`;
    document.title = `Журнал - Фото-Фитнес-Студия ArtPro`;
  }
})
export default class ComponentJournal extends Vue {
  msg!: string
}
