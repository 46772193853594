<template>
  <div class="hello">
    <h2>Обложки журнала ArtPro {{ $route.params.year ? $route.params.year : 2023 }}</h2>
  </div>

  <div v-if="! $route.params.year">
    <div class="MainImage OffsetTop">
      <a href="/resources/images/photos/journal/2023/4_DSC3110_Обложка_1280.jpg"><img src="/resources/images/photos/journal/2023/4_DSC3110_Обложка_1280.jpg"></a>
    </div>

    <div class="rowHorizontal OffsetTop">
      <a href="/resources/images/photos/journal/2023/3_2_DSC1442_Обложка_1280.jpg"><img src="/resources/images/photos/journal/2023/3_2_DSC1442_Обложка_1280.jpg"></a>
      <a href="/resources/images/photos/journal/2023/3_1_DSC2190_Обло_жка_1280.jpg"><img src="/resources/images/photos/journal/2023/3_1_DSC2190_Обло_жка_1280.jpg"></a>
      <a href="/resources/images/photos/journal/2023/2_DSC0532_Обложка_1536.jpg"><img src="/resources/images/photos/journal/2023/2_DSC0532_Обложка_1536.jpg"></a>
      <a href="/resources/images/photos/journal/2023/1_2_DSC9750_Обложка_1280.jpg"><img src="/resources/images/photos/journal/2023/1_2_DSC9750_Обложка_1280.jpg"></a>
      <a href="/resources/images/photos/journal/2023/1_1_DSC9448_Обложка_1280.jpg"><img src="/resources/images/photos/journal/2023/1_1_DSC9448_Обложка_1280.jpg"></a>
    </div>
  </div>

  <div v-if="$route.params.year == 2022">
    <div class="MainImage OffsetTop">
      <a href="/resources/images/photos/journal/2022/10_DSC3872_Обложка.jpg"><img src="/resources/images/photos/journal/2022/10_DSC3872_Обложка.jpg"></a>
    </div>

    <div class="rowHorizontal OffsetTop">
      <a href="/resources/images/photos/journal/2022/12_2_DSC8569_2_Обложка_1536.jpg"><img src="/resources/images/photos/journal/2022/12_2_DSC8569_2_Обложка_1536.jpg"></a>
      <a href="/resources/images/photos/journal/2022/12_1_DSC8694_Обложка_1536.jpg"><img src="/resources/images/photos/journal/2022/12_1_DSC8694_Обложка_1536.jpg"></a>
      <a href="/resources/images/photos/journal/2022/11_DSC7024_Обложка.jpg"><img src="/resources/images/photos/journal/2022/11_DSC7024_Обложка.jpg"></a>
      <a href="/resources/images/photos/journal/2022/9_DSC2811_Обложка.jpg"><img src="/resources/images/photos/journal/2022/9_DSC2811_Обложка.jpg"></a>
      <a href="/resources/images/photos/journal/2022/8_DSC5628_Обложка_1920.jpg"><img src="/resources/images/photos/journal/2022/8_DSC5628_Обложка_1920.jpg"></a>
      <a href="/resources/images/photos/journal/2022/7_DSC4773_Обложка_1536.jpg"><img src="/resources/images/photos/journal/2022/7_DSC4773_Обложка_1536.jpg"></a>
      <a href="/resources/images/photos/journal/2022/6_DSC3294_Обложка_2_1080.jpg"><img src="/resources/images/photos/journal/2022/6_DSC3294_Обложка_2_1080.jpg"></a>
      <a href="/resources/images/photos/journal/2022/5_2_DSC1329_Обложка_1920.jpg"><img src="/resources/images/photos/journal/2022/5_2_DSC1329_Обложка_1920.jpg"></a>
      <a href="/resources/images/photos/journal/2022/5_1_DSC3275_Обложка_1920.jpg"><img src="/resources/images/photos/journal/2022/5_1_DSC3275_Обложка_1920.jpg"></a>

      <a href="/resources/images/photos/journal/2022/4_DSC3134_Обложка_2048.jpg"><img src="/resources/images/photos/journal/2022/4_DSC3134_Обложка_2048.jpg"></a>
      <a href="/resources/images/photos/journal/2022/3_2_DSC9599_Обложка.jpg"><img src="/resources/images/photos/journal/2022/3_2_DSC9599_Обложка.jpg"></a>
      <a href="/resources/images/photos/journal/2022/3_1_DSC8507_Обложка.jpg"><img src="/resources/images/photos/journal/2022/3_1_DSC8507_Обложка.jpg"></a>
      <a href="/resources/images/photos/journal/2022/2_DSC4998_Обложка.jpg"><img src="/resources/images/photos/journal/2022/2_DSC4998_Обложка.jpg"></a>
      <a href="/resources/images/photos/journal/2022/1_DSC4102_Обложка.jpg"><img src="/resources/images/photos/journal/2022/1_DSC4102_Обложка.jpg"></a>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    msg: String,
    year : Number
  },
  // watch: {
  //   title: {
  //     immediate: true,
  //     handler() {
  //       let year = this.$route.params.year ? this.$route.params.year : 2023;
  //       document.title = `Журнал ${year} - Фото-Фитнес-Студия ArtPro`;
  //     }
  //   }
  // }
  created () {
    // let year = this.$route.params.year ? this.$route.params.year : 2023;
    // document.title = `Журнал ${year} - Фото-Фитнес-Студия ArtPro`;
    document.title = `Журнал - Фото-Фитнес-Студия ArtPro`;
  }
})
export default class ComponentJournal extends Vue {
  msg!: string
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.divTextAward {
margin-top : 0.5em;
}
</style>
