
import {Options, Vue} from 'vue-class-component'
import ComponentProfile from '@/components/Profile.vue' // @ is an alias to /src
import ComponentGalleries from '@/components/Galleries.vue'

@Options({
  components: {
    ComponentProfile,
    ComponentGalleries
  },

  mounted () {
    // ComponentProfile.addQueryProcessingSubscriber (ComponentGalleries.initialize);
  }
})
export default class Profile extends Vue {}
