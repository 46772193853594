
import { Options, Vue } from 'vue-class-component'
import ComponentJournal from '@/components/Journal.vue' // @ is an alias to /src
import ComponentJournalNavigation from '@/components/JournalNavigation.vue'

@Options({
  components: {
    ComponentJournal,
    ComponentJournalNavigation
  }
})
export default class Journal extends Vue {}
