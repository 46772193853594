<template>
  <div class="about">
<h2>Правила работы в студии ArtPro</h2>

<h3>При аренде студии действуют следующие правила :</h3>
<ul>
<li>при аренде студии нужно внести предоплату 50% стоимости аренды времени работы в студии не позднее, чем за 48 часов до аренды.</li>
<li>при аренде менее, чем за 48 часов до съёмок, нужно внести 50% стоимости в течении часа после договорённости об аренде.</li>
<li>аренда студии от 1 часа.</li>
<li>в стоимость аренды включены все <b>кольцевые лампы</b>,<br/>
светодиодный осветитель <b>Godox SL-150W</b>,<br/>
все <b>кукурузные светодиодные лампы</b>, <b>цоколи</b> для их применения и <b>зонты</b>,<br/>
все световые штативы, журавли, отражатели и светоформирующие насадки,<br/>
моноблоки <b>Godox DE 400</b> и <b>DE 400 II</b>,<br/>
накамерная вспышка <b>Godox TT 520 II</b> и<br/>
галогеновый источник света <b>Falcon Eyes QL-1000BW v2.0</b>.<br/><br/>

<b>Дополнительное оборудование</b> :<br/>
Аренда моноблока <b>Godox SK 400 II</b> стоит дополнительно +50 рублей к стоимости аренды часа работы в студии,<br/>
быстрая вспышка <b>Godox QT600II</b> + 200 рублей к стоимости часа аренды студии,<br/>
<b>штатив</b> для фото-камер + 100 рублей к стоимости часа аренды студии и<br/>
<b>HDMI</b>-карты видео-захвата +200 рублей ( за штуку ) к стоимости часа аренды студии.</li>

<li>в стоимость аренды студии включена работа во всех фотозонах студии по выбору арендаторов.</li>
<li>в стоимость аренды включена работа 4-х человек в студии, если в студии больше 4-х человек, то пребывание каждого последующего стоит +100 рублей за человека.<br/><br/>
Для <b>Модельных Агентств</b> действуют специальные правила : фотограф и стилист могут снимать одновременно 1 - 2 модели и визажист может параллельно наносить макияж ещё 4 - 6 моделям.<br/><br/>
Для <b>Мастер-Классов</b> у нас в студии действуют специальные условия :<br/>
работа 4-х человек включена в стоимость аренды студии ( фотограф, ведущий, стилист, администратор и подобные специалисты, организующие съёмки, или слушатели ) и за каждых дополнительных 10 слушателей оплата 100 рублей.</li>
<li>клиенты в основном сами настраивают оборудование так, как им нужно, администраторы студии могут включить оборудование и подсказать как настраивать свет - дальше фотографы должны сами ставить свет, так как это задача фотографов, у администраторов задача смотреть за тем, чтобы в студии поддерживался порядок и арендаторы студии работали в соответствии с правилами.</li>
<li>арендаторы оплачивают время работы в студии и далее могут снимать в течении этого времени, необходимо за 2 минуты до начала следующей аренды предоставить студию следующим арендаторам.</li>
<li>в студии необходимо находиться в сменной обуви или в носках, уличную обувь нужно оставлять около входа в студию.</li>
<li>в случае поломки оборудования, если в этом есть вина арендаторов студии, например, арендаторы уронили оборудование или ударили о другое оборудование - арендаторы выплачивают компенсацию, равную стоимости оборудования. Если оборудование вышло из строя по причине износа компонентов, то в этом нет вины арендаторов - и в этом случае они не оплачивают стоимость оборудования.<br/>
в случае поломки аксессуаров для позирования, интерьера студии или загрязнения студии - арендаторы оплачивают компенсацию, равную стоимости аксессуаров, ремонта или очистки студии.</li>
<li>если до съемки менее 1 суток, за отмену или перенос берется компенсация, равная 50% стоимости забронированных услуг.</li>
</ul>

<h3>Правила работы со специфическим оборудованием</h3>
<h4>Виниловые и бумажные фоны</h4>
<p>При работе на виниловых и бумажных фонах - необходимо вставать на такой фон только в обуви с плоской подошвой, заклеенной малярным скотчем или в носках.<br/>
Для съёмок в обуви с острыми краями каблуков и туфлями со шпильками - нужно ложить на пол белый матовый пластик.<br/>
В случае протыкания или разрыва фонов клиентами - они выплачивают компенсацию в размере части стоимости такого фона, в зависимости от того, какую часть фона они повредили.</p>

<h4>Светофильтры со вспышками</h4>
<p>При применении светофильтров со вспышками, нужно отключать пилотный свет, чтобы не прожечь светофильтры.</p>

<h3>Сертификаты</h3>
<p>Вы можете приобрести у нас сертификат на 10 часов аренды студии по стоимости 5800 р - их нужно отснять полностью или частями в течении двух месяцев с момента покупки сертификата.</p>

<h3>Правила заказов съёмок :</h3>
<ul>
<li>Съёмки можно заказать и забронировать заранее при условии внесения предоплаты 50% стоимости не позднее, чем за 3 дня до съёмок.</li>
<li>Можно заказать и забронировать съёмки через менее чем 3 дня при условии внесения предоплаты 50% стоимости в течении часа после заказа съёмок.</li>
<li>Если для организации съёмок требуются действия и затраты с нашей стороны : поиск и аренда платьев, организация выездных съёмок, полёт в другой город и так далее - то нужно внести предоплату 50% стоимости съёмок и полную оплату этих дополнительных услуг и расходов не позднее 5 дней до фото-сессии.</li>
<li>Вторую часть оплаты стоимости съёмок нужно внести до начала фото-сессии, можно непосредственно перед съёмками.</li>
</ul>

<h3>Политика возвратов денежных средств</h3>
<h4>Аренда студии</h4>
<ul>
<li>Можно вернуть предоплату только за 24 часа до времени аренды, после этого времени предоплата не возвращается.</li>
<li>После съёмок оплату за аренду можно вернуть только если не было возможности провести съёмки по нашей вине : например, отказало оборудование в виду естественного износа ( если оно было сломано арендаторами, то оплата не возвращается и арендаторы оплачивают стоимость ремонта или покупки нового аналогичного оборудования ), в случае отсутствия оборудования, о наличии которого мы договорились с арендаторами, или по другим подобным причинам.</li>
<li>В случае форс-мажорных, не зависящих от нас причин, оплата аренды после съёмок не возвращается.</li>
<li>По любым другим причинам оплата аренды не возвращается.</li>
</ul>

<h4>Съёмки</h4>
<ul>
<li>Предоплату съёмок можно вернуть не позднее, чем за 2 дня до фото-сессии.</li>
<li>Оплату съёмок можно вернуть только в случае несоответствия результатов ТЗ - после обсуждения с заказчиками.</li>
<li>Стоимость оказания дополнительных услуг : поиск и аренда платьев, работа стилистов и визажистов и так далее - возвращается только если результат не соответствует ТЗ - после обсуждения с заказчиками.</li>
<li>Стоимость расходов по организации съёмок с нашей стороны : организация выездных фото-сессий, полёты в другие города и так далее - не возвращается.</li>
<li>По любым другим причинам оплата съёмок не возвращается.</li>
</ul>

  </div>
</template>



<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  created () {
    document.title = "Правила - Фото-Фитнес-Студия ArtPro";
  },
})
export default class Rules extends Vue {}

</script>
