<template>
  <div class="MarginTopMedium">
    <div id="divClientGalleries" v-if="m_showGalleries">
      <h3>Галереи</h3>

      <div id="divGalleriesControls">
        <label for="edName">Название (2 - 128 символов): </label>
        <input type="text" id="edName" name="edName" required
         minlength="2" maxlength="128"> 
        <input id="btCreateGallery" type="button" value="Добавить" v-on:click="btCreateGallery_OnClick">
      </div>

      <div id="divGalleries" class="MarginTopSmall">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {
  URL_YANDEX_OAUTH2_LOGIN,
  URL_VK_OAUTH2_LOGIN,
  URL_LOGOUT,
  URL_DATA_PROCESSOR_CLIENTS_OUR_ACCOUNT_RELATIVE
} from '@/system/constants';
import {ClientsService} from '@/system/services/ClientsService';
import {
  ICollection,
  CollectionsService
} from '@/system/services/CollectionsService';
import {EventsProcessor} from '@/system/common_code/EventsProcessor';

@Options({
  props: {
    // m_showGalleries: Boolean
  },

  data: () => ({
    m_showGalleries: false
  }),

  // created () {
  // },

  async mounted () {
    // const isLoggedIn = ClientsService.isLoggedIn ();

    // // console.log (`Is client logged in is : ${isLoggedIn}.`);
    // if (isLoggedIn) {
    //   await this.showClientGalleries ();

    // }

    EventsProcessor.addEventSubscriber ("eventProfileQueryProcessed", this.initialize);

    // this.$nextTick(
    // setTimeout (async () => {
    //   const isLoggedIn = ClientsService.isLoggedIn ();
    //   if (isLoggedIn) {
    //     this.receiveGalleries ();
    //   }
    // }, 500);
  },

  methods: {
    async initialize () {
      const isLoggedIn = ClientsService.isLoggedIn ();

      // console.log (`Is client logged in is : ${isLoggedIn}.`);
      if (isLoggedIn) {
        // const collections = await CollectionsService.getCollections ();
        // // console.log (`Collections: ${JSON.stringify (collections)}.`);
        // if (collections) {
        //   this.$nextTick(() => {
        //     this.showReceivedGalleries (collections);
        //   });
        // }
        await this.showClientGalleries ();
      }
    },

    hideClientGalleries: function () {
      const divClientGalleries = document.getElementById ("divClientGalleries");
      if (divClientGalleries) {
        // setTimeout (() => {
          // console.log (`Hiding divClientGalleries ${JSON.stringify (divClientGalleries)}.`);
          // divClientGalleries.style.display = "none";
          // divClientGalleries.classList.add ("Hidden");
        // }, 2000);
      }
    },
    showClientGalleries: async function () {
      const divClientGalleries = document.getElementById ("divClientGalleries");
      console.log (`Galleries.showClientGalleries () : divClientGalleries is : ${divClientGalleries}.`);
      if (divClientGalleries) {
        // console.log (`Showing divClientGalleries ${JSON.stringify (divClientGalleries)}.`);

        // divClientGalleries.style.display = "block";
        // divClientGalleries.style.display = "inline-block";
        // divClientGalleries.style.display = "block";
        // divClientGalleries.style.visibility = "visible";
        // divClientGalleries.classList.remove ("Hidden");
        // divClientGalleries.classList.add ("Visible");
        this.m_showGalleries = true;
        this.receiveGalleries ();
      }
    },

    receiveGalleries: async function (): Promise <void> {
      const collections = await CollectionsService.getCollections ();
        // console.log (`Collections: ${JSON.stringify (collections)}.`);
        if (collections) {
          this.showReceivedGalleries (collections);
        }
    },

    showReceivedGalleries: function (inputGalleries: ICollection []): void {
      let i = 0;

      this.divGalleries = document.getElementById ("divGalleries");
      if (this.divGalleries) {
        this.divGalleries.innerHTML = "";
        for (i = 0; i < inputGalleries.length; i ++) {
          this.showReceivedGallery (inputGalleries [i]);
        }
      }
    },
    showReceivedGallery : function (inputGallery: ICollection): void {
      console.log (inputGallery);
      this.divGalleries.insertAdjacentHTML ("beforeend",
        `<div><a href="/galleries/${inputGallery.id}">${inputGallery.name}</a></div>`
      );
    },

    btCreateGallery_OnClick: async function () {
      const edName = document.getElementById ("edName") as HTMLInputElement;
      if (edName) {
        console.log ("Galleries.btCreateGallery_OnClick () : Function has been called.");
        await CollectionsService.add (edName.value);
        // window.location.href = `/profile/`;
        this.receiveGalleries ();
      }
    }
  }
})
export default class ComponentProfile extends Vue {
  msg!: string
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .Hidden {
    /*display: none;*/
    visibility: hidden;
  }

  .Visible {
    display: block;
  }

  #btCreateGallery {
    margin-left: 1em;
  }
</style>
