<template>
  <div class="awards">
    <ComponentAwards msg="Награды студии ArtPro и фотографов студии"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ComponentAwards from '@/components/Awards.vue' // @ is an alias to /src

@Options({
  components: {
    ComponentAwards
  }
})
export default class Awards extends Vue {}
</script>