import { IEvent } from '@/system/data_sources/DataSource_Events'; // @ is an alias to /src
import { Utilities } from '@/system/common_code/Utilities';

export class Event {
    static eventToElementsInList ( inputEvent : IEvent ) : Node {
        const template = document.createElement ( 'template' );
        template.innerHTML = '<div><a href="/events/' + inputEvent.idHumanReadable + '">' +
            '<h3>' + inputEvent.title + '</h3>' +
            // '<p>' + inputEvent.p1 + '</p>' +
            '</a></div>';
        return template.content.firstChild as Node;
    }

    static eventToElements ( inputEvent : IEvent ) : Node | string {
        if ( ! inputEvent || ! inputEvent.images1 || ! inputEvent.images2 ) {
            return "";
        }

        const template = document.createElement ( 'template' );
        let images1 = "";
        let images2 = "";
        let i = 0;

        for ( i = 0; i < inputEvent.images1.length; i ++ ) {
            images1 = images1 + inputEvent.images1 [ i ];
        }

        for ( i = 0; i < inputEvent.images2.length; i ++ ) {
            images2 = images2 + inputEvent.images2 [ i ];
        }

        template.innerHTML = '<div>' +
            '<h3>' + inputEvent.title + '</h3>' +
            '<div class="rowHorizontal">' + images1 + '</div>' +
            '<p>' + inputEvent.p1 + '</p>' +
            '<div class="rowHorizontal">' + images2 + '</div>' +
            '<p>' + inputEvent.p2 + '</p>' +
            ( inputEvent.p3 ? '<p>' + inputEvent.p3 + '</p>' : '' ) +
            ( inputEvent.p4 ? '<p>' + inputEvent.p4 + '</p>' : '' ) +
            '</div>';
        return template.content.firstChild as Node;
    }
}