<template>
  <!--h3>{{ idHumanReadable }}</h3-->
  <!--p>{{ p1 }}</p-->

  <div id="divEvent">
  </div>

  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { Event } from '@/components/Event.ts';

import { DataSource_Events } from '@/system/data_sources/DataSource_Events';

@Options({
  async mounted () {
    const divEvent = document.getElementById ( "divEvent" );

    if ( divEvent ) {
      const event = await DataSource_Events.getEvent ( this.idHumanReadable );
      if ( event ) {
        divEvent.append ( Event.eventToElements ( event ) );
      } //-for
    } //-if
  },

  props: {
    // Caption: String,
    // Images1 : String,
    // p1 : String,
    // p2 : String,
    // Images2 : String

    idHumanReadable : String
  }
})
export default class ComponentEventDetails extends Vue {
  // Caption!: string;
  // Images1! : string;
  // p1! : string;
  // p2! : string;
  // Images2! : string;

  idHumanReadable : string = "";
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
