
import { Options, Vue } from 'vue-class-component'

@Options({
  created () {
    document.title = "Правила - Фото-Фитнес-Студия ArtPro";
  },
})
export default class Rules extends Vue {}

