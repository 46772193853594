<template>
  <div class="hello">
    <h2>{{ msg }}</h2>
  </div>

  <div class="row">
  <div class="column">
    <a href="/resources/images/awards/35awards_682_ru_1280.png"><img src="/resources/images/awards/35awards_682_ru_1280.png"></a>
    <a href="/resources/images/awards/35awards2021_ru_1280.png"><img src="/resources/images/awards/35awards2021_ru_1280.png"></a>
    <a href="/resources/images/awards/35awards_644_ru_1080.png"><img src="/resources/images/awards/35awards_644_ru_1080.png"></a>
    <a href="/resources/images/awards/35awards_635_ru_1080.png"><img src="/resources/images/awards/35awards_635_ru_1080.png"></a>
    <a href="/resources/images/awards/35awards_627_ru_1080.png"><img src="/resources/images/awards/35awards_627_ru_1080.png"></a>
    <a href="/resources/images/awards/35awards_622_ru_1280.png"><img src="/resources/images/awards/35awards_622_ru_1280.png"></a>
    <a href="/resources/images/awards/35awards_604_ru_1280.png"><img src="/resources/images/awards/35awards_604_ru_1280.png"></a>
    <a href="/resources/images/awards/35awards2020_ru_1280.png"><img src="/resources/images/awards/35awards2020_ru_1280.png"></a>
    <a href="/resources/images/awards/35awards_610_ru_1280.png"><img src="/resources/images/awards/35awards_610_ru_1280.png"></a>
    <!--div class="divTextAward"><a href="https://mywed.com/ru/photographer/RodionKarimov/" rel="nofollow">MyWed portfolio</a></div-->
    <div class="divTextAward"><a href="https://500px.com/p/rodionkarimov1984" rel="nofollow">500px rating</a></div>
    <div class="VerticalSpacer_Middle"></div>
  </div>
  <div class="column">
  </div>
  <div class="column">
  </div>
  <div class="column">
  </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    msg: String
  },
  created () {
    document.title = "Награды - Фото-Фитнес-Студия ArtPro";
  },
})
export default class ComponentAwards extends Vue {
  msg!: string
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.divTextAward {
margin-top : 0.5em;
}
</style>
