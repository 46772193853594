<template>
  <div class="awards">
    <ComponentJournal msg="2023"/>
    <ComponentJournalNavigation/>
  </div>

  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ComponentJournal from '@/components/Journal.vue' // @ is an alias to /src
import ComponentJournalNavigation from '@/components/JournalNavigation.vue'

@Options({
  components: {
    ComponentJournal,
    ComponentJournalNavigation
  }
})
export default class Journal extends Vue {}
</script>