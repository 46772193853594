<template>
  <div class="events">
  	<!--{{ $route.params.id }}-->
    <ComponentEventDetails :idHumanReadable="$route.params.id"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ComponentEventDetails from '@/components/EventDetails.vue' // @ is an alias to /src

@Options({
  components: {
    ComponentEventDetails
  }
})
export default class Event extends Vue {}
</script>