<template>
  <div class="hello">
    <h2>{{ msg }}</h2>
  </div>

  <div class="row">
  <div class="column">
    <a href="/resources/images/equipment_and_interiors/Light/_DSC3974.jpg"><img src="/resources/images/equipment_and_interiors/Light/_DSC3974.jpg"></a>
  </div>
  <div class="column">
    <a href="/resources/images/equipment_and_interiors/Light/_DSC3974.jpg"><img src="/resources/images/equipment_and_interiors/Light/_DSC3974.jpg"></a>
  </div>
  <div class="column">
    <a href="/resources/images/equipment_and_interiors/Light/_DSC3974.jpg"><img src="/resources/images/equipment_and_interiors/Light/_DSC3974.jpg"></a>
  </div>
  <div class="column">
    <a href="/resources/images/equipment_and_interiors/Light/_DSC3974.jpg"><img src="/resources/images/equipment_and_interiors/Light/_DSC3974.jpg"></a>
  </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    msg: String
  }
})
export default class componentEqipmentAndInteriors extends Vue {
  msg!: string
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
