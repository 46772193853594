<template>
  <div class="events">
    <ComponentEvents msg="События студии ArtPro"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ComponentEvents from '@/components/Events.vue' // @ is an alias to /src

@Options({
  components: {
    ComponentEvents
  }
})
export default class Awards extends Vue {}
</script>