
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    msg: String
  },
  created () {
    document.title = "Награды - Фото-Фитнес-Студия ArtPro";
  },
})
export default class ComponentAwards extends Vue {
  msg!: string
}
