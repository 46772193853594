import axios from 'axios';

export interface IEvent {
    idHumanReadable?: string;
    title : string;
    p1 : string;
    p2? : string;
    p3? : string;
    p4? : string;
    imageTitle : string;
    images1? : string [];
	images2? : string [];
}

export class DataSource_Events {
    static async getEvents () : Promise < IEvent [] > {
        // const events = [] as IEventInList [];

        const response = await axios.get('/resources/data/events/events.json');
        // console.log ( stringEvents );
        // const events = JSON.parse ( stringEvents ) as IEventInList [];
			// .then(response => (
			// 	this.info = response
			// ));

		const events = response.data.events;

        return events;
    }

    static async getEvent ( inputHumanReadableId : string ) : Promise < IEvent | null > {
    	const response = await axios.get('/resources/data/events/events.json');
		const events = response.data.events;
		let i = 0;

		for ( i = 0; i < events.length; i ++ ) {
			if ( events [ i ].idHumanReadable === inputHumanReadableId ) {
				return events [ i ];
			}
		} //-for

		return null;
    }
}
