
// import * as libraryVue from 'vue';
import { Options, Vue } from 'vue-class-component';
import { DataSource_Events } from '@/system/data_sources/DataSource_Events'; // @ is an alias to /src
// import ComponentEvent from '@/components/Event.vue'; // @ is an alias to /src
import { Event } from '@/components/Event.ts';

import { Utilities } from '@/system/common_code/Utilities';

// const classEvent = libraryVue.Vue.extend ( ComponentEvent );
// console.log ( libraryVue );
// console.log ( ComponentEvent.constructor );

@Options({
  async mounted () {
    let i = 0;
    const divEvents = document.getElementById ( "divEvents" );

    if ( divEvents ) {
      const events = await DataSource_Events.getEvents ();
      // divEvents.innerHTML = JSON.stringify ( events );
      for ( i = events.length - 1; i >=0 ; i -- ) {
        // divEvents.append (
        // divEvents.innerHTML =
        //   '<ComponentEvent Caption="Съёмки студии ArtPro в Москве 17 - 18 апреля"\
        //     p1="Первый параграф"\
        //     Images1="<img src=/>"\
        //   />';
        // );

        // const instance = new classEvent ();
        // const instance = new ComponentEvent ();
        // instance.$mount ();

        // divEvents.append ( instance.$el );
        // console.log ( instance.$refs );
        // divEvents.append ( instance.$refs.divEventHolder );

        // divEvents.append ( Utilities.htmlToElements ( '<div>Test</div>' ) );
        divEvents.append ( Event.eventToElementsInList ( events [ i ] ) );
      } //-for
    } //-if
  },

  // components: {
  //   ComponentEvent
  // },

  props: {
    msg: String
  }
})
export default class ComponentEvents extends Vue {
  msg!: string
}
